.loader
    z-index: 50
    width: 100%
    height: 100%
    position: fixed
    top: 0px
    left: 0px

    &.light
        background-color: #f0f0f0

    &.dark
        background-color: #f0f0f0

    .spinner
        z-index: 51
        position: absolute
        transform: translate(-50%, -50%)
        top: 50%
        left: 50%
        width: 220px
        height: 220px

        .jar
            width: 220px
            // -webkit-filter: drop-shadow(5px 5px 5px #222)
            // filter: drop-shadow(0 9px 9px rgba(0, 0, 0, 0.3))

        .jam
            position: absolute
            z-index: 52
            width: 120px
            height: 120px

        .vinyl
            z-index: 52
            width: 120px
            height: 120px
            animation: spin 3s linear infinite

        .arm
            z-index: 52
            width: 120px
            height: 120px
            position: absolute
            top: calc(50% - 2px)
            left: calc(50% - 2px)
            transform: translate(-50%, -50%)
            animation: bob 1s linear infinite

@keyframes spin
    100%
        transform: rotate(360deg)

@keyframes bob
    50%
        transform: translate(-50%, -50%) rotate(10deg)
    100%
        transform: translate(-50%, -50%) rotate(0deg)
