.footer
    z-index: 60,
    position: fixed
    bottom: 62px
    left: 0px
    width: calc(100% - 60px)
    display: flex
    margin: 15px 30px
    box-shadow: 0 9px 9px 0 rgba(0,0,0,.1), 0 0 29px 0 rgba(0,0,0,.1)
    border-radius: 4px
    overflow: hidden
    background-color: white
    transition: bottom 0.4s ease

    .back-mover
        background-color: #1c1c1c
        position: absolute
        top: 0px
        left: 0px
        height: 100%
        width: calc(100% / 3)
        transition: left 0.4s ease

    .item
        z-index: 3
        flex: 1
        text-align: center
        // background-color: #f0f0f0
        font-size: 20px
        margin-top: 12px
        margin-bottom: 12px
        transition: color 0.4s ease, background-color 0.2s ease
        border-right: 1px solid #00000030
        cursor: pointer

        &:last-child
            border-right: none

        .icon
            transition: transform 0.4s ease, color 0.4s ease
            will-change: transform color

            &.active
                // background-color: #1c1c1c
                color: #f0f0f0
                transform: scale(1.2)
