.history
    margin-bottom: 30px
    position: relative

    &:last-child
        margin-bottom: 92px

    .date
        font-family: sans-serif
        // font-weight: bold
        font-size: 12px
        border-top-left-radius: 4px
        border-top-right-radius: 4px
        position: relative
        z-index: 15
        text-transform: uppercase

    .history-item
        margin-top: 20px
        margin-bottom: 15px

        .item-key
            font-family: sans-serif
            font-weight: bold
            display: inline-block
            vertical-align: middle
            width: 22px

        .history-card
            background-color: white
            border-radius: 4px
            overflow: hidden
            display: inline-block
            vertical-align: middle
            width: calc(100%)
            position: relative

            .image
                vertical-align: middle
                width: 120px

            .info
                margin-left: 15px
                display: inline-block
                vertical-align: middle
                width: calc(100% - 135px)
                height: 105px
                padding-top: 15px

                .date
                    font-family: sans-serif
                    font-size: 12px
                    margin-bottom: 5px
                    font-size: 10px

                .album, .artists
                    white-space: nowrap
                    overflow: hidden
                    text-overflow: ellipsis
                    font-family: sans-serif
                    font-size: 12px
                    margin-right: 5px

                .album
                    font-weight: bold

                .options
                    position: absolute
                    right: 0px
                    bottom: 0px
                    padding: 5px

                    .button
                        display: inline-block
                        vertical-align: middle
                        margin-bottom: 5px
                        font-size: 18px
                        padding: 0px 5px
                        color: #686868
                        cursor: pointer
                        line-height: 6px

                        &:hover
                            color: black

                    .play
                        color: #1cd760
                        font-size: 46px
                        margin: 5px 5px 5px 10px
                        border-radius: 50px
                        box-shadow: 0 4px 8px rgba(0,0,0,.3)

        &:last-child
            margin-bottom: 0px
