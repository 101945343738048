.home
    margin-bottom: 92px
    margin-top: 20px

    .options
        float: right

        .button
            display: inline-block
            vertical-align: middle
            font-size: 24px
            padding: 5px 10px
            color: #686868
            cursor: pointer
            line-height: 10px

            &:hover
                color: black
