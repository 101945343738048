.saved
    margin-bottom: 30px

    .saved-item
        margin-bottom: 15px
        margin-top: 20px

        &:last-child
            margin-bottom: 92px

    .no-saved
        margin-top: 30px
        text-align: center
        font-family: sans-serif

        .heading
            font-size: 30px
            margin-bottom: 45px

        .subheading
            font-size: 14px
            width: 210px
            margin: auto

        .cta
            margin-top: 15px
            padding: 10px 15px
            background-color: black
            color: white
            border-radius: 4px
            box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.1), 0 0 29px 0 rgba(0, 0, 0, 0.1)
            display: inline-block
            margin: 30px auto 0px
            width: 230px

            &:last-child
                background-color: white
                color: black
                margin: 15px auto 0px
