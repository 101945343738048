.login
    text-align: center

    .spotify-logo
        width: 200px
        margin: auto
        margin-top: 30px

    .reasons
        text-align: center

        ul
            max-width: 200px
            text-align: left
            display: inline-block
            font-family: sans-serif
            font-size: 16px
            padding-inline-start: 20px
            color: #686868

            li
                margin: 10px 0

    .spotify-connect
        background-color: #1cd760
        border: none
        border-radius: 50px
        overflow: hidden
        max-width: 200px
        padding: 5px 15px
        margin: auto

        .symbol
            width: 30px
            vertical-align: middle

        .text
            display: inline-block
            vertical-align: middle
            font-family: sans-serif
            color: #f0f0f0
            font-weight: bold

    .or
        max-width: 120px
        margin: 30px auto 18px
        text-align: center
        font-size: 12px

        hr
            margin-bottom: -7px
            border: none
            background-color: #9a9a9a
            height: 1px

        .text
            font-family: sans-serif
            color: #9a9a9a
            background-color: #f0f0f0
            display: inline-block
            padding: 0px 5px

    .guest
        color: #9a9a9a
        font-family: sans-serif